* {
    margin: 0;
    padding: 0;

}

h2 {
    font-size: 7vw;
    font-family: 'DM Sans', serif;
    font-weight: 300;
    width: fit-content;
    border-bottom: 2px solid black;
    margin-left: 1vw;

}

h3 {
    font-size: 1.5vw;
    font-family: 'DM Sans', serif;
    font-weight: 300;
    margin-left: 1vw;

    line-height: 2;
}

.container {
    background-image: url("./images/Noise.png");
    height: fit-content;
    display: flex;
    gap: 40px;
    flex-direction: column;
    width: 100%;
    padding-top: 50px;
    padding-bottom: 100px;
    position: relative;
}

*::-webkit-scrollbar {
    display: none;
}

/* Hide the scrollbar for Internet Explorer, Edge and Firefox */
.keep-scrolling {
    -ms-overflow-style: none;
    /* Internet Explorer and Edge */
    scrollbar-width: none;
    /* Firefox */
}