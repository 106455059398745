footer {
    background-color: black;
    color: white;
    width: calc(100%-20px);
    padding-left: 20px;
    padding-top: 20px;
    padding-bottom: 20px;
    font-weight: 200;
    font-family: 'DM Sans', serif;
    font-size: 26px;
}