#PremierePage {
    padding-top: 0;
    overflow: hidden;
}

#PremierePage>* {
    overflow: hidden;
}

#PremierePage h1 {
    color: black;
    text-align: center;
    font-family: 'DM Serif Display', serif;
    font-size: 10vw;
    font-weight: 100;
    width: 95%;
    margin: auto;
    border-bottom: 1px solid black;
    line-height: 1;
}


#PremierePageReseaux {
    width: fit-content;
    height: fit-content;
    display: flex;
    justify-content: center;
    gap: 40px;
    margin: auto;
    padding-top: 20px;

}

#PremierePageReseaux>img {
    width: 60px;
    aspect-ratio: 1/1;
}


#PremierePageDocuments {
    width: 100%;
    margin: auto;
    display: flex;
    height: fit-content;
    justify-content: center;
    gap: 50px;

}

#PremierePageVector {
    width: 400px;
    position: absolute;
    top: 0;
    left: 85vw;
    top: 30vh
}

#PremierePageDocuments>div {
    font-family: 'DM Sans', serif;
    height: fit-content;
    flex-basis: 20%;

    display: flex;
    flex-direction: column;
    gap: 15px;
    position: relative;

    -webkit-transition: all 500ms ease-in-out;
    -moz-transition: all 500ms ease-in-out;
    -o-transition: all 500ms ease-in-out;
    transition: all 500ms ease-in-out;

}

@media (max-width: 820px) {
    #PremierePageDocuments {
        flex-wrap: wrap;
    }
}

#PremierePageDocuments>div:hover {
    flex-basis: 25%;
}

#PremierePageDocuments>div>p {
    font-size: 24px;
    font-weight: 600;
    max-width: 90%;
}

#PremierePageDocuments .title {
    color: gray;
    font-weight: bold;
    letter-spacing: 0.5em;
    max-width: 300px;
}

#PremierePageDocuments>div>img {
    width: 100%;
    aspect-ratio: 1/1;
    border-radius: 10px;
}

#PremierePageDocuments>div>.goto {
    position: absolute;
    display: block;
    width: 40px;
    aspect-ratio: 1/1;
    top: 10px;
    right: 10px;
}

#PremierePageReseaux>img:hover,
#PremierePageDocuments>div>.goto:hover {
    cursor: pointer;
}