#TroisiemePageButtons {
    width: fit-content;
    height: fit-content;
    display: flex;
    gap: 20px;
    margin-left: 1vw;
}

#TroisiemePageButtons>button {
    width: fit-content;
    padding: 1.6vw;
    padding-top: 0.9vw;
    padding-bottom: 0.9vw;
    font-size: 1.3vw;
    background-color: black;
    border: none;
    border-radius: 0.3vw;
    color: white;
    font-family: 'DM Sans', serif;
}

#TroisiemePageButtons>button:hover {
    cursor: pointer;
}

#TroisiemePage {
    padding-bottom: 10%;
}

#TroisiemePage h3 {
    max-width: 44vw;
}

#TroisiemeVector {
    width: 20%;
    position: absolute;
    top: 0;
    left: 2%;
    top: 108%;
    transform: translate(0%, -100%);
    z-index: -1;
}

#TroisiemeWorking {
    height: 65%;
    position: absolute;
    top: 0;
    left: 100%;
    top: 10%;
    transform: translate(-100%, 0%)
}

@media (max-width: 1400px) {
    #TroisiemePage h3 {
        max-width: 100vw;
    }

    #TroisiemeWorking {

        display: none;
    }
}