#SecondPage {
    background-color: black;
    color: white;
    position: relative;
}

#SecondPage>* {
    overflow: hidden;
}

#SecondPage h2 {
    background-color: black;
    color: white;
    border-bottom-color: gray;
}

#SecondPageVector {
    height: 80%;
    position: absolute;
    top: 0;
    left: 100%;
    top: 100%;
    transform: translate(-100%, -100%)
}

#SecondPage h3 {
    max-width: 44vw;
}

@media (max-width: 1400px) {
    #SecondPageVector {
        display: none;
    }

    #SecondPage h3 {
        max-width: 100vw;
    }
}